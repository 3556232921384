import React from 'react';
import socketIOClient from 'socket.io-client';
import { WEBSOCKET_EVENT } from '../constants';
import Swal from 'sweetalert2';

const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = React.useState(null);

  React.useEffect(() => {
    const newSocket = socketIOClient(process.env.REACT_APP_WEBSOCKET_URL);

    newSocket.on(WEBSOCKET_EVENT.NOTIFY_JOB_DELAY, async (data) => {
      await Swal.fire({
        title: 'Delayed Job',
        text: `Job ID ${data.jobId} delayed, please reassign Fabricator`,
        icon: 'info',
        confirmButtonText: 'OK',
      });
    });

    newSocket.on(WEBSOCKET_EVENT.NOTIFY_JOB_DEFECTIVE_PRODUCT, async (data) => {
      await Swal.fire({
        title: 'Defected Product',
        text: `Job ID ${data.jobId} defected, please reassign Fabricator`,
        icon: 'info',
        confirmButtonText: 'OK',
      });
    });

    newSocket.on(WEBSOCKET_EVENT.NOTIFY_INPUT_TRACKING_NUMBER, async (data) => {
      await Swal.fire({
        title: 'Ready to Deliver jobs',
        text: `Order ${data.orderId} is ready for delivery, please input tracking numbers for job/jobs ${data.jobIds}`,
        icon: 'info',
        confirmButtonText: 'OK',
      });
    });

    setSocket(newSocket);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const value = React.useMemo(() => ({ socket }), [socket]);

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default {
  Consumer: SocketContext.Consumer,
  Provider: SocketProvider,
};
