import React, { Suspense } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import './assets/css/tailwind.output.css';
import App from './App';
import { SidebarProvider } from './context/SidebarContext';
import ThemedSuspense from './components/ThemedSuspense';
import { Windmill } from '@windmill/react-ui';
import * as serviceWorker from './serviceWorker';
import { Provider as ReduxProvider } from 'react-redux';
import reduxStore from './store';

const MUITheme = createTheme({
  palette: {
    primary: {
      main: '#3baa01',
    },
    secondary: {
      main: '#B5EB9A',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={MUITheme}>
    <ReduxProvider store={reduxStore}>
      <SidebarProvider>
        <Suspense fallback={<ThemedSuspense />}>
          <Windmill dark={false}>
            <App />
          </Windmill>
        </Suspense>
      </SidebarProvider>
    </ReduxProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
