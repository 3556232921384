import React, { useState, useMemo, useCallback } from 'react';

// create context
export const SidebarContext = React.createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isIcon, setIsIcon] = useState(false);

  const toggleSidebar = useCallback(() => setIsSidebarOpen(!isSidebarOpen), [isSidebarOpen]);
  const toggleSidebarIcon = useCallback(() => setIsIcon(!isIcon), [isIcon]);

  function closeSidebar() {
    setIsSidebarOpen(false);
  }

  const value = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
      isIcon,
      toggleSidebarIcon,
    }),
    [isSidebarOpen, toggleSidebar, isIcon, toggleSidebarIcon]
  );

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
