import _ from 'lodash';

export const SET_COMMENT = 'job.comments.set';
export const ADD_COMMENT = 'job.comments.add';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_COMMENT: {
      const { data } = action.payload;
      return _.keyBy(data, 'id');
    }
    case ADD_COMMENT:
      return {
        ...state,
        [action.payload.id]: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
