import _ from 'lodash';

export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const USER_ROLE = {
  ADMIN: 'admin',
  DISPATCHER: 'dispatcher',
  FABRICATOR: 'fabricator',
};

export const JOB_STATUS = {
  BACKLOG: 'backlog',
  RETURNED: 'returned',
  ASSIGNED: 'assigned',
  IN_MANUFACTURE: 'in_manufacture',
  MANUFACTURE_COMPLETE: 'manufacture_complete',
  WITH_POWDER_COATER: 'with_powder_coater',
  STORAGE: 'storage',
  PACKAGING: 'packaging',
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
  INSTALLATION: 'installation',
  COMPLETED: 'completed',
};

export const JOB_STATUSES_INPUT_TRACKING_NUMBER = _.pick(
  JOB_STATUS,
  [JOB_STATUS.PACKAGING, JOB_STATUS.DELIVERY, JOB_STATUS.INSTALLATION, JOB_STATUS.COMPLETED].map(
    (e) => _.toUpper(e)
  )
); //JOB_STATUSES.PACKAGING ONWARD

export const ORDER_STATUSES = {
  PENDING: 'wc-pending',
  PROCESSING: 'wc-processing',
  ON_HOLD: 'wc-on-hold',
  COMPLETED: 'wc-completed',
  CANCELLED: 'wc-cancelled',
  REFUNDED: 'wc-refunded',
  FAILED: 'wc-failed',
  RETURN_REQUESTED: 'wc-return-requested',

  //CUSTOM ORDER STATUS
  WAITING: 'waiting',
  BACKLOG: 'backlog',
  _PROCESSING: 'processing',
  _ON_HOLD: 'on-hold',
  _PENDING: 'pending',
  ASSIGNED: 'assigned',
  IN_PROGRESS: 'in_progress',
  READY: 'ready',
  _COMPLETED: 'completed',
};

export const PRODUCT_TYPE = {
  SNORKEL: 'Snorkel',
  AIRBOX: 'Airbox',
  APPAREL: 'Apparel',
  GIFTCARD: 'Gift Card',
  INTAKE_PIPING: 'Intake Piping',

  // handle dynamic product type; from db.tag.name
  STICKER: 'Sticker',
};

export const JOB_PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
};

export const CONFIGURATIONS = {
  STANDARD_DUE_DATE: '1',
  EXPRESS_DUE_DATE: '2',
};

export const REPORT_TYPE = {
  SNORKEL: 'snorkels',
  AIRBOX: 'airboxes',
  INTAKE_PIPING: 'intakepiping',
  MERCHANDISE: 'merchandises',
  STICKER: 'stickers',
};

export const REPORT_RECURRENCE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const REPORT_CATEGORY = {
  SALE: 'sale',
  PRODUCE: 'produce',
};

export const TAG_ID = {
  EXPRESS_SERVICE: 246,
  STANDARD_SERVICE: 245,
  ENGINE_VARIANT: 250,
  FINISH_OPTIONS: 242,
  STICKER: 241,
  SNORKEL: 238,
  AIRBOX: 239,
  INSURANCE: 243,
  GIFT_CARD: 251,
  EXTRAS: 248,
  APPAREL: 240,
  INTAKE_PIPING: 249,
  SNORKEL_STYLE: 247,
};

export const ORDER_TYPE = {
  STANDARD: 'standard',
  EXPRESS: 'express',
};

export const ORDER_TYPE_PRODUCT_ID = {
  STANDARD: [6700, 3568],
  EXPRESS: [3566, 10904, 6699],
};

export const DELIVERY_METHOD = {
  INSTALLATION: 'installation',
  DELIVER: 'deliver',
  PICK_UP: 'pickup',
};

export const DELIVERY_METHOD_PRODUCT_ID = {
  INSTALLATION: [4046, 4097, 17200],
  DELIVER: [4041],
  PICK_UP: [4045],
};

export const REPORT_PRODUCT_CATEGORIES = {
  SNORKELS: 'snorkels',
  INTAKE_PIPING: 'intakepiping',
  AIRBOXES: 'airboxes',
  MERCHANDISES: 'merchandises',
};

export const JOBS_PAGE_COLUMNS = {
  JOB_ID: 'Job ID',
  ORDER_ID: 'Order ID',
  PRODUCT: 'Product',
  ASSIGNED_TO: 'Assigned To',
  CREATED: 'Created',
  JOB_DUE_DATE: 'Job Due Date',
  ORDER_DUE_DATE: 'Order Due Date',
  STATUS: 'Status',
  PRIORITY: 'Priority',
};

export const ORDERS_PAGE_COLUMNS = {
  ORDER_ID: 'Order ID',
  CUSTOMER: 'Customer',
  ORDER_DATE: 'Order Date',
  DELIVERY_DATE: 'Delivery Date',
  STATUS: 'Status',
  SERVICE: 'Service',
};

export const ORDERS_PAGE_CONDITION_DROPDOWN = {
  ACTIVE: 'active',
  DELETED: 'deleted',
};

export const WEBSOCKET_EVENT = {
  NOTIFY_JOB_DELAY: 'ws-job-delay',
  NOTIFY_JOB_DEFECTIVE_PRODUCT: 'ws-job-defective-product',
  NOTIFY_INPUT_TRACKING_NUMBER: 'ws-job-input-tracking-number',
};

export const FINISH_OPTIONS = {
  RAW: 'raw',
  POLISHED: 'polished',
};
export const STYLE_OPTIONS = {
  STANDARD: 'standard',
  LOWCUT: 'lowcut',
};

export const CUSTOM_FINISH_ABBREVIATION = {
  'COLOR CODED 2 PACK PAINT': 'CC2PP',
  'CUSTOM COLOUR (POWDER COAT)': 'CCPC',
};

export const TRACKING_TYPES = {
  JOB: 'job',
  SHIFT: 'shift',
};

export const TRACKING_JOB = {
  IN_MANUFACTURE: [JOB_STATUS.IN_MANUFACTURE, JOB_STATUS.MANUFACTURE_COMPLETE],
  INSTALLATION: [JOB_STATUS.INSTALLATION, JOB_STATUS.COMPLETED],
};

export const TRACKING_SHIFT = {
  LOGIN: ['login', 'logout'],
};

export const WORK_DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

export const CHECKLIST_TYPE = {
  SNORKEL: 'snorkel',
  AIRBOX: 'airbox',
  INTAKE_PIPING: 'intake-piping',
  COMBO: 'combo',
};

export const CHECKLIST_TYPE_LABEL = {
  snorkel: 'Snorkel Only',
  airbox: 'Airbox Only',
  'intake-piping': 'Intake Piping Only',
  combo: 'Combo',
};

export const INSURANCE_TYPE = {
  NO_INSURANCE: false,
  USE_INSURANCE: true,
};

export const INSURANCE_LABEL = {
  false: 'No Insurance',
  true: 'Use Insurance',
};

export const INSURANCE_PRODUCT_ID = {
  NO_INSURANCE: [3563],
  USE_INSURANCE: [3561, 3576, 3577],
};

export const SNORKEL_SILENCER_TYPE = {
  NO_HUSH: false,
  USE_HUSH: true,
};

export const SNORKEL_SILENCER_LABEL = {
  false: 'None',
  true: 'Hush',
};

export const PRE_EXISTING_SNORKEL_TYPE = {
  NO: false,
  YES: true,
};
export const PRE_EXISTING_SNORKEL_LABEL = {
  false: 'No',
  true: 'Yes',
};

export const SERVICE_TYPE = {
  STANDARD: false,
  EXPRESS: true,
};

export const SERVICE_LABEL = {
  false: 'Standard',
  true: 'Express',
};

export const BUILT_IN_AIRBOX = [4657, 17296, 4653];

export const CUSTOM_FINISH_OPTIONS = [3539, 3540, 3542, 3545, 3547, 5894, 5895, 5896];

export const CUSTOM_STICKER_OPTIONS = [
  3549, 3550, 3551, 3552, 3553, 3554, 3554, 3555, 3556, 3557, 3558, 3559, 3560,
];

export const SNORKEL_SILENCER_OPTIONS = [45893, 45894];

export const INDIVIDUALLY_PURCHASED_STICKER_PRODUCT_ID = [46641];
