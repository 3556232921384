export const SET_CURRENT_USER = 'current-user.set';

let tokenData = window.localStorage.getItem('tokenData');

if (tokenData) {
  tokenData = JSON.parse(tokenData);
}

const initialState = {
  id: tokenData?.id,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return action.data;
    default:
      return state;
  }
};

export default reducer;
