import axios from 'axios';
import { setResource, overwriteResource, updateResource } from '../store/actions/resources';

export const baseURL = process.env.REACT_APP_API_URL;
const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  // these configuration will be passed to response and we can automatically map data to redux store
  config.resourceName = config.headers.resourceName;
  config.overwrite = config.headers.overwrite;

  return config;
});

export const interceptResponse = (dispatch, getState) => {
  instance.interceptors.response.use((res) => {
    const { config, data } = res;
    if (!config.resourceName) return res;
    if (config.overwrite) {
      dispatch(overwriteResource(config.resourceName, data));
    } else if (config.method === 'patch') {
      dispatch(updateResource(config.resourceName, { id: data.id, data }));
    } else {
      dispatch(setResource(config.resourceName, data));
    }
    return res;
  });
};

export default instance;
