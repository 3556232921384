import React, { lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import SocketContext from './context/SocketContext';

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));

const App = () => (
  <SocketContext.Provider>
    <Router>
      <AccessibleNavigationAnnouncer />
      <Switch>
        <Route path="/login" component={Login} />

        {/* Place new routes over this */}
        <Route path="/app" component={Layout} />
        {/* If you have an index page, you can remove this Redirect */}
        <Redirect exact from="/" to="/login" />
      </Switch>
    </Router>
  </SocketContext.Provider>
);

export default App;
