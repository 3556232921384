import { combineReducers } from 'redux';
import _ from 'lodash';

export const RESOURCE_NAMES = {
  USERS: 'users',
  ORDERS: 'orders',
  PRODUCTS: 'products',
  CONFIGURATIONS: 'configurations',
  JOBS: 'jobs',
  TAGS: 'tags',
  COMBO_PRODUCTS: 'combo-products',
  LOGS: 'logs',
  CHECKLIST_ITEMS: 'checklist-items',
  CHECKLISTS: 'checklists',
  CHECKLIST_CHECK: 'checklist-checks',
};
const reducer =
  (resourceName) =>
  (state = {}, action) => {
    let temp = {};
    switch (action.type) {
      case `resources.${resourceName}.set`: {
        const { data } = action.payload;
        const _data = _.isArray(data) ? data : [action.payload];
        return {
          ...state,
          ..._.keyBy(_data, 'id'),
        };
      }
      case `resources.${resourceName}.update`:
        return {
          ...state,
          [action.payload.id]: {
            ..._.cloneDeep(state[action.payload.id]),
            ...action.payload.data,
          },
        };
      case `resources.${resourceName}.delete`:
        temp = _.cloneDeep(state);
        delete temp[action.payload];
        return temp;
      case `resources.${resourceName}.overwrite`:
        const { data } = action.payload;
        const data1 = _.isArray(data) ? data : [action.payload];
        return {
          ..._.keyBy(data1, 'id'),
        };
      default:
        return state;
    }
  };

const allReducers = {};
for (let f in RESOURCE_NAMES) {
  allReducers[RESOURCE_NAMES[f]] = reducer(RESOURCE_NAMES[f]);
}

export default combineReducers(allReducers);
