import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import * as reducers from './reducers';
import { interceptResponse } from '../utils/axios';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers(reducers);
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

interceptResponse(store.dispatch, store.getState);

export default store;
